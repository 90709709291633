import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/san-diego-historical-homes-vintage-real-estate"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_San Diego's Historical Homes_ A Look at Vintage Real Estate.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                If you’re like most people, San Diego makes you think of the idyllic climate,
                beaches, and stunning sunsets—not historical architecture. So it may surprise you
                that our city is a treasure trove of beautiful vintage homes. From Victorian
                mansions to Craftsman bungalows, these architectural time capsules offer a unique
                window into San Diego's past. Let's look at some of our favorite historical
                districts in San Diego.
              </CommonParagraph>
              <Heading type="h2">Old Town</Heading>
              <CommonParagraph>
                Founded in 1769, Old Town is the site of the first European settlement in the area.
                This neighborhood is located at Interstates 5 and 8, which puts you minutes away
                from Mission Bay, Sunset Cliffs, the international airport, and two parks—Old Town
                San Diego State Historic Park and Presidio Park.
              </CommonParagraph>
              <CommonParagraph>
                If you’re looking for historical architecture in San Diego, you’ll find 27 buildings
                in the state park, plus a nice mix of Victorian, Craftsman, and Spanish-style homes
                outside of it.
              </CommonParagraph>
              <Heading type="h2">Gaslamp Quarter</Heading>
              <CommonParagraph>
                The Gaslamp Quarter is quintessentially "old meets new." Here, you'll find a
                16-square-block district where Victorian-era buildings are scattered alongside
                modern architecture. While once a seedy red light district, things have certainly
                changed in the Gaslamp.
              </CommonParagraph>
              <CommonParagraph>
                94 historical Victorian buildings now house some of the city's best restaurants,
                bars, shops, and homes. When you come by, be on the lookout for these local faves:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>Ornate ironwork balconies and decorative cornices on many buildings </li>
                <li>The iconic Gaslamp Quarter archway sign at 5th and L Street</li>
                <li>The Horton Grand Hotel, a restored 1886 Victorian-era hotel</li>
                <li>
                  The Davis-Horton House, the oldest surviving structure in downtown San Diego
                </li>
              </ul>
              <Heading type="h2">Burlingame</Heading>
              <CommonParagraph>
                Situated in central San Diego, just east of Balboa Park, Burlingame was developed in
                1910 as one of the city's first suburbs. Here, you'll find an eclectic mix of San
                Diego’s most charming historical home styles, including:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>Craftsman Bungalow</li>
                <li>Tudor Revival</li>
                <li>Mission Revival</li>
                <li>Spanish Colonial Revival</li>
                <li>Prairie School</li>
                <li>Art Deco</li>
                <li>California Ranch</li>
              </ul>
              <CommonParagraph>
                This tree-lined, 10-block area is one of the most pedestrian-friendly in the city,
                putting you within walking distance of the shops and restaurants of North Park. From
                our point of view, Burlingame is a perfect example of how historical neighborhoods
                can maintain their integrity while still integrating with modern styles.
              </CommonParagraph>
              <Heading type="h2">Little Italy</Heading>
              <CommonParagraph>
                Many ethnic districts continue to be swallowed up as cities evolve, but that's not
                the story in San Diego. Welcome to Little Italy, a booming business and residential
                community that remains vibrant over 100 years after it first took shape.
              </CommonParagraph>
              <CommonParagraph>
                This charming San Diego historical district may have struggled in the 1960s and
                1970s due to declining fishing industries and the construction of Interstate 5,
                which took out a chunk of the district. However, Little Italy was revived in the
                1990s and is now a thriving collection of public art, fantastic food, shopping, and
                an eclectic mix of old and new architecture.
              </CommonParagraph>
              <Heading type="h2">Mission Hills</Heading>
              <CommonParagraph>
                Mission Hills is one of the oldest neighborhoods in San Diego. Here, you'll find
                many well-preserved homes from the turn of the century. Plus, you'll find many
                mid-century homes, craftsman bungalows, and plenty of Spanish revival.
              </CommonParagraph>
              <CommonParagraph>
                Beyond real estate, this neighborhood provides easy access to freeways and the
                airport. Plus, it's home to a rich and diverse culinary scene.
              </CommonParagraph>
              <Heading type="h2">South Park</Heading>
              <CommonParagraph>
                We're all for progress and reinvention, but it does hurt a little when we see
                historic buildings replaced by tall mega buildings.
              </CommonParagraph>
              <CommonParagraph>
                Fortunately, that hasn't happened in this half-century-old neighborhood.
              </CommonParagraph>
              <CommonParagraph>
                Welcome to South Park, where you'll find a well-preserved collection of Victorian
                and Craftsman properties scattered amongst modern townhouses, condos, and
                single-family homes. If you appreciate architecture, are looking for a
                family-friendly area, and love tree-lined streets, this may be the location for you.
              </CommonParagraph>
              <Heading type="h2">Ready to Make Your Move? The Selby Team Can Help.</Heading>
              <CommonParagraph>
                Whether you're drawn to the charm of historical homes or the sleek lines of modern
                architecture, <BlogLink url="https://selbysellssd.com/" text="The Selby Team" /> can
                help you find your perfect match in San Diego. Our team understands this diverse
                city’s unique real estate landscape and is on call, ready to bring you to the
                doorstep of your dream home. <ContactSlideoutLink text="Contact us" /> today!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
